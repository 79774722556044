<template>
    <CContainer fluid>
        <CRow>
            <CCol class="col-12 col-xl-4">
                <CCard>
                    <CCardHeader>
                        <strong>Yeni Şablon Ekle</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12">
                                <CInput label="İsim" placeholder="İsim" v-model="name" />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="12">
                                <CInput label="Şablon Kimliği" placeholder="Şablon Kimliği" v-model="templateId" />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="mt-3 col-12 col-md-6" v-if="!isUpdate">
                                <CButton @click="createNewTemplate" block color="success">Yeni Şablon Ekle</CButton>
                            </CCol>
                            <CCol class="mt-3 col-12 col-md-6" v-if="isUpdate">
                                <CButton @click="updateTemplate" block color="success">Şablonu Güncelle</CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol class='col-12 col-xl-8'>
                <CCard>
                    <CCardHeader>
                        <strong>Kum Izgarası Şablonları</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable :items="tableData" :fields="fields" tableFilter hover :loading="isDataLoading">
                            <template #show_details="{ item }">
                                <td class="py-2" style="display: inline-flex;">
                                    <CButton @click="editTemplate(item)" color="success" class="ml-1 mb-1">
                                        <CIcon name="cil-pencil" />
                                    </CButton>
                                    <CButton @click="deleteAlert(item.id)" color="danger" class="ml-1 mb-1">
                                        <CIcon name="cil-x" />
                                    </CButton>
                                    <CButton v-if="item.Status === 'In-active'" @click="updatePackageStatus(item.id)"
                                        color="success" class="mb-1 ml-1">
                                        <CIcon name='cil-CheckCircle' />
                                    </CButton>
                                    <CButton v-if="item.Status === 'Active'" @click="updatePackageStatus(item.id)"
                                        color="danger" class="mb-1 ml-1">
                                        <CIcon name='cil-XCircle' />
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </CContainer>
</template>

<script>
import { AppointmentAdministration, TeacherAdministration, SendGridEmailTemplates } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import { v4 as uuidv4 } from 'uuid';
import router from "@/router";

export default {
    name: 'SendGridTemplates',
    Vcomponents: {
        VueLoadingButton,
    },
    mounted() {
        this.loadData()
    },
    watch: {
    },
    data() {
        return {
            status: 'Paid',
            tableData: [],
            name: '',
            templateId: '',
            id: null,
            isDataLoading: false,
            isUpdate: false,
            fields: [
                { key: "id", label: "İD" },
                { key: "name", label: "İsim" },
                { key: "templateId", label: "Şablon Kimliği" },
                { key: "Status", label: "Durum" },
                {
                    key: 'show_details',
                    label: 'Aksiyon',
                    _style: 'min-width:20%',
                    filter: false,
                    sorter: false,
                },
            ]
        }
    },
    computed: {
    },
    methods: {
        async loadData() {
            this.isListLoading = true;
            const result = await SendGridEmailTemplates.listEmailTemplate()
            console.log(result);
            let array = result.data.referralList.map(f => {
                return {
                    id: f.id,
                    name: f.name,
                    templateId: f.templateId,
                    Status: f.isActive ? 'Active' : "In-active"
                }
            })
            this.tableData = array;
        },
        async createNewTemplate() {
            this.isDataLoading = true;
            try {
                let data = {
                    name: this.name,
                    templateId: this.templateId
                }
                const result = await SendGridEmailTemplates.createEmailTemplate(data)
                this.loadData();
                this.$toast.success("Yeni Yönetici Başarıyla Oluşturuldu!")
                this.name = '';
                this.templateId = '';
            } catch (e) {
            }
            this.isDataLoading = false;
        },

        async deleteAlert(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: "Yaptığınız İşlem Geri Alınamayacaktır!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.tableData = this.tableData.filter(r => r.id !== id)
                    await SendGridEmailTemplates.deleteEmailTemplate(id)
                    await this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success'
                    )
                }
            });
        },
        async editTemplate(item) {
            this.id = item.id
            this.isUpdate = true
            this.name = item.name,
                this.templateId = item.templateId
        },
        async updateTemplate() {
            this.isDataLoading = true;
            try {
                let data = {
                    id: this.id,
                    name: this.name,
                    templateId: this.templateId
                }
                const result = await SendGridEmailTemplates.updateEmailTemplate(data)
                this.loadData()
                this.$toast.success("Yeni Yönetici Başarıyla Oluşturuldu!")
                this.name = '';
                this.templateId = ''
                this.id = null
                this.isUpdate = false
            } catch (e) {
            }
            this.isDataLoading = false;
        },

        async updatePackageStatus(id) {
            this.$swal({
                title: 'Onayla',
                text: "Seçilen bu şablonun durumunu değiştirmek istediğinizden emin misiniz?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Onaylamak',
                cancelButtonText: 'Vazgeç'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let obj ={
                        id:id
                    }
                    await SendGridEmailTemplates.updateStatusEmailTemplate(obj)
                    this.loadData();
                    await this.$swal.fire(
                        'Onaylanmış!',
                        'Durum başarıyla değiştirildi!',
                        'success'
                    )
                }
            });
        },
    },

}
</script>
